import React from 'react';
import { take } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import type { IFeedItem } from 'api/feed/types';

import * as feed from 'settings/feed';

import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { Stack } from 'wui/Stack';
import { Tooltip } from 'wui/Tooltip';
import { CardContent } from 'wui/CardContent';
import { Typography } from 'wui/Typography';
import { TextButton } from 'wui/TextButton';
import {
  AvatarGroup,
  AvatarGroupItem,
  AvatarGroupSize,
} from 'wix-ui-tpa/cssVars';

import { selectHasReactions, selectReactedUsers } from './selectors';
import { ReactedMembersDialog } from './ReactedMembersDialog';

import classes from '../FeedItem.scss';

interface IProps {
  legacy?: boolean;
  item: IFeedItem;

  toggleComments?(): void;
}

export function ReactedMembers(props: IProps) {
  const { item, legacy, toggleComments } = props;

  const { t } = useTranslation();
  const settings = useSettings();

  const feedItemId = item.feedItemId as string;

  const users = useSelector(selectReactedUsers(feedItemId));
  const hasReactions = useSelector(selectHasReactions(feedItemId));

  const [isModalOpened, setIsModalOpened] = React.useState(false);

  const previewProfiles = take(users, 20).map<AvatarGroupItem>((user) => ({
    lettersLimit: 2,
    name: user.name,
    src: user.imageUrl,
  }));

  if (!hasReactions) {
    return null;
  }

  return (
    <CardContent
      direction="horizontal"
      align="space-between"
      verticalAlign="middle"
    >
      <div>
        <Wire
          cssVarName="showReactions"
          legacyFallback={settings.get(feed.settings.showReactions)}
        >
          <Tooltip
            placement="top"
            content={
              <Stack direction="vertical">
                {previewProfiles.map((profile, index) => (
                  <Typography noWrap variant="p2-12" key={index}>
                    {profile.name}
                  </Typography>
                ))}
                <Show if={previewProfiles.length < users.length}>
                  <span>...</span>
                </Show>
              </Stack>
            }
          >
            <AvatarGroup
              maxAmount={4}
              items={previewProfiles}
              size={AvatarGroupSize.xSmall}
              className={classes.reactedMembers}
            >
              <AvatarGroup.TextButton
                onClick={
                  item.permissions?.canViewFullPost ? openDialog : undefined
                }
              >
                {t('groups-web.discussion.feed.reactions.total_icu', {
                  count: item.reactions.total,
                })}
              </AvatarGroup.TextButton>
            </AvatarGroup>
          </Tooltip>
        </Wire>
      </div>
      <Typography variant="p2-14" className={classes.totalComments}>
        <TextButton variant="secondary" onClick={toggleComments}>
          {t('groups-web.discussion.feed.total-comments_icu', {
            count: item.comments?.total || item.latestComments?.total || 0,
          })}
        </TextButton>
      </Typography>

      <ReactedMembersDialog
        item={item}
        legacy={legacy}
        isOpen={isModalOpened}
        onClose={closeDialog}
      />
    </CardContent>
  );

  function openDialog() {
    setIsModalOpened(true);
  }

  function closeDialog() {
    setIsModalOpened(false);
  }
}

ReactedMembers.displayName = 'ReactedMembers';
