import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { IPublicPlan } from 'api/pricing-plans/types';

import { Typography } from 'wui/Typography';
import { Show } from 'wui/Show';

interface PricingPlanDetailsProps {
  plan: IPublicPlan;
}

export function PricingPlanDetails({ plan }: PricingPlanDetailsProps) {
  const { t } = useTranslation();

  if (!plan || !plan.translatedDetails) {
    return t('groups-web.discussion.feed.paid-post.preview.no-plan-info');
  }

  const { price, cycle, duration, freeTrial, additionalFees } =
    plan.translatedDetails;

  const extraFees = additionalFees
    .map((fee) => `+ ${fee.amount} ${fee.name}`)
    .join(', ');

  return (
    <Typography variant="p2-16">
      {[price, cycle, duration, freeTrial, extraFees]
        .filter((x) => x)
        .join(', ')}
      <Show if={Boolean(plan.buyerCanCancel)}>
        &nbsp; |{' '}
        {t('groups-web.discussion.feed.paid-post.preview.buyer-can-cancel')}
      </Show>
    </Typography>
  );
}

PricingPlanDetails.displayName = 'PricingPlanDetails';
